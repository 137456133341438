import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTE_NO_ORGANIZATIONS, ROUTE_APP_LOADING_ERROR } from '../../routes';
import { initialiseApp } from '../../store/actions/global';

const getOrganizationIdFromUrl = () => {
    const url = window.location.pathname;
    return /^\/([0-9]+)\//.exec(url)?.[1];
};

const useInitializeApp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);

    const loadApp = () => {
        if (!initialized) {
            const organizationId = getOrganizationIdFromUrl() ?? (localStorage.getItem('currentOrganization') ?? "0");

            dispatch(initialiseApp(organizationId))
                .then(({ currentOrganization, organizations }) => {
                    if (!currentOrganization || !organizations?.length) {
                        navigate(ROUTE_NO_ORGANIZATIONS());
                    } else {
                        localStorage.setItem('currentOrganization', currentOrganization?.id);
                    }
                })
                .catch(e => {
                    console.log(e);
                    navigate(ROUTE_APP_LOADING_ERROR());
                })
                .finally(() => setInitialized(true));
        }
    };
    useEffect(loadApp, [dispatch, navigate, initialized]);

    return {};
};

export default useInitializeApp;