import { STARTED, SUCCEEDED, FAILED } from '../actionTypes';

export const createAction = (options = { type: '', payload: {} }) => (dispatch) => dispatch({
    type: options.type,
    payload: options.payload,
  });
  
export const createAsyncAction = ({ call, type, payload = {} }) => (dispatch) => {
    dispatch({
        type: type[STARTED],
    });
    return call(payload)
    .then(data => {
        dispatch({
        type: type[SUCCEEDED],
        payload: data,
        });
        return data;
    })
    .catch(error => {
        dispatch({
        type: type[FAILED],
        payload: error,
        });
        throw error;
    });
};