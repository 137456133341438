import { createGlobalStyle } from "styled-components";

import { FONTS, COLOURS } from './constants/styles';

export const GlobalStyle = createGlobalStyle`

    :root {
        --toastify-color-success: ${COLOURS.SUCCESS};
        --toastify-color-warning: ${COLOURS.WARNING};
        --toastify-color-error: ${COLOURS.DANGER}; 
    }

    * {
        font-family: ${FONTS.FAMILY};
        font-size: ${FONTS.SIZES.REGULAR};
    }
    input, button { 
        outline: none;
    }
    a {
        text-decoration: none;
        color: ${COLOURS.PRIMARY};
        transition: filter 0.5s;

        &:hover {
            color: ${COLOURS.PRIMARY};
        }
    }
    body {
        background-color: ${COLOURS.BACKGROUND};
        margin: 0;
        height: 100vh;
        color: ${COLOURS.FONTS.DARK.PRIMARY};
    }

    h1, h2, h3, h4, h5 {
        color: ${COLOURS.FONTS.DARK.PRIMARY};
    }

    .modal-content {
        background-color: ${COLOURS.BACKGROUND};
        border: 0;
    }

    .modal-header, .modal-footer, .modal-content {
        border: 0;
    }
`;