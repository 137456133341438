import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers';

const configureStore = (initialState = {}) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const createEnhancedStore = composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
        ),
    )(createStore);

    return createEnhancedStore(rootReducer, initialState);
};

const store = configureStore();

export default store;
