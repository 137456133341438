
import { createAsyncAction } from './actionCreators';

import * as types from '../actionTypes';
import * as api from '../../api';

export const initialiseApp = organizationId => {
  return createAsyncAction({
    type: types.INITIALIZE_APP,
    call: api.INITIALIZE_APP,
    payload: { organizationId }
  });
};

export const loginUser = payload => {
  return createAsyncAction({
    type: types.LOGIN_USER,
    call: api.LOGIN_USER,
    payload
  });
};

export const updateCurrentOrganization = (organizationId, payload) => {
  return createAsyncAction({
    type: types.UPDATE_CURRENT_ORGANIZATION,
    call: api.UPDATE_CURRENT_ORGANIZATION,
    payload: { organizationId, payload }
  });
};

export const updateCurrentUser = (payload) => {
  return createAsyncAction({
    type: types.UPDATE_CURRENT_USER,
    call: api.UPDATE_CURRENT_USER,
    payload,
  });
};

export const getOrganizationStatus = (organizationId) => {
  return createAsyncAction({
    type: types.GET_ORGANIZATION_STATUS,
    call: api.GET_ORGANIZATION_STATUS,
    payload: { organizationId },
  });
};