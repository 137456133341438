import { LOCALHOST, PRODUCTION, STAGING } from "../requests/urls"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})


export function setCookie(name, value, time) {
    let expires = '';
    if (time) {
        const date = new Date();
        date.setTime(date.getTime() + time * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/; SameSite=Strict; Secure`;
}

export function getCookie(name) {
    const nameEQ = `${name}=`;
    // console.log('cookie', document.cookie)
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getEnvironment = () => {
    if (window.location.hostname.includes('app.')) {
        return 'PRODUCTION';
    } else if (window.location.hostname.includes('app-staging.')) {
        return 'STAGING';
    } else {
        return 'LOCAL';
    }
};

//check url for request
export function getApiBaseUrl() {
    //console.log('my function', window.location.hostname)

    if (window.location.hostname.includes('app.')) {
        return PRODUCTION;
    } else if (window.location.hostname.includes('app-staging.')) {
        return STAGING;
    } else {
        return LOCALHOST;
    }
}

//check url for request
export function checkUrl () {
  //console.log('my function', window.location.hostname)

     if (window.location.hostname.includes('app.')) {
       return PRODUCTION
     } else if (window.location.hostname.includes('app-staging.')) {
       return STAGING
     } else {
       return LOCALHOST
     }
 };

export const copyToClipboard = async text => {
  try {
      await navigator.clipboard.writeText(text);
      return true;
  } catch (e) {
      console.log(e);
      return false;
  }
};

export const convertToSentenceCase = text => text.replace(
  /\w\S*/g,
  function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }
);

export function formatValue(value) {
  if (typeof value == 'number') {

    if (Math.floor(value) !== value) {
      return value.toFixed(2);
    }
    return value;
  }


  return value;
};