import Axios from 'axios';

import { getCookie, getEnvironment } from '../utils/Utils';

const URLS = {
    PRODUCTION: 'https://api.hacuna.io',
    STAGING: 'https://api-staging.hacuna.io',
    LOCAL: 'http://localhost:3001',
};

const API = URLS[getEnvironment()];

const getHeaders = () => ({ Authorization: `Bearer ${getCookie('jwt')}` });

const requests = {
    get: async url => {
            const headers = getHeaders();
            return Axios({
                method: 'get',
                url:`${API}${url}`,
                headers: headers,
            }
        ).then((response) => response.data)
    },
    post: async (url, data = {}) => {
            const headers = getHeaders();
            return Axios({
                method: 'post',
                data,
                url:`${API}${url}`,
                headers: headers,
            }
        ).then((response) => response.data)
    },
    put: async (url, data = {}) => {
            const headers = getHeaders();
            return Axios({
                method: 'put',
                data,
                url:`${API}${url}`,
                headers: headers,
            }
        ).then((response) => response.data)
    },
    delete: async (url, data = {}) => {
            const headers = getHeaders();
            return Axios({
                method: 'delete',
                url:`${API}${url}`,
                headers: headers,
            }
        ).then((response) => response.data)
    },
};

export default requests;
