import React from 'react';
import PropTypes from 'prop-types';

import { LOADER_MODES, LOADER_KINDS, LOADER_SIZES, LOADER_THEMES, StyledLoadingContainer, StyledLoadingMessage } from './Loading.styles';
import LoadingWheel from './LoadingWheel';
import LoadingEllipsis from './LoadingEllipsis';

const propTypes = {
    kind: PropTypes.oneOf(Object.values(LOADER_KINDS)),
    mode: PropTypes.oneOf(Object.values(LOADER_MODES)),
    theme: PropTypes.oneOf(Object.values(LOADER_THEMES)),
    size: PropTypes.oneOf(Object.values(LOADER_SIZES)),
    message: PropTypes.string,
};

const defaultProps = {
    kind: LOADER_KINDS.WHEEL,
    mode: LOADER_MODES.FILL_PARENT,
    theme: LOADER_THEMES.DARK,
    size: LOADER_SIZES.REGULAR,
    message: undefined,
};

const Loading = ({ kind, mode, message, size, theme }) => (
    <StyledLoadingContainer mode={mode}>
        <div>
            {
            (kind === LOADER_KINDS.WHEEL && <LoadingWheel theme={theme} size={size} />)
            || (kind === LOADER_KINDS.ELLIPSIS && <LoadingEllipsis theme={theme} size={size} />)
            }
        </div>
        {!!message && <StyledLoadingMessage>{message}</StyledLoadingMessage>}
    </StyledLoadingContainer>
);

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;