import { useState } from 'react';
import { onAuthStateChanged } from '@firebase/auth';

import { LOGIN_USER } from '../../api';
import { setCookie } from '../Utils';

const useAuth = ({ auth }) => {
    const [loggedIn, setLoggedIn] = useState(null);

    onAuthStateChanged(auth, user => {
        if (user) {
            LOGIN_USER({ accessToken: user.accessToken })
                .then(({ jwtToken }) => {
                    setCookie('jwt', jwtToken, 2);
                    setLoggedIn(true);
                })
                .catch(e => {
                    console.log(e);
                    setLoggedIn(true);
                });
        } else {
            setLoggedIn(false);
        }
    });

    return { loggedIn };
};

export default useAuth;