import { INITIALIZE_APP, UPDATE_CURRENT_ORGANIZATION, UPDATE_CURRENT_USER, GET_ORGANIZATION_STATUS, STARTED, SUCCEEDED } from "../actionTypes";
import { APP_STATUS } from "../../constants/global";

const initialState = {
  appStatus: APP_STATUS.INITIALIIZING,

};

const globalReducer = (state = initialState, action) => {
  if (action.type === INITIALIZE_APP[STARTED]) {
    return {
        ...state,
        appStatus: APP_STATUS.INITIALIZING,
    };
  }
  else if (action.type === INITIALIZE_APP[SUCCEEDED]) {
    return {
        appStatus: APP_STATUS.LOADED,
        currentUser: action.payload.user,
        currentOrganization: action.payload.currentOrganization,
        organizations: action.payload.organizations
    };
  }
  else if (action.type === UPDATE_CURRENT_ORGANIZATION[SUCCEEDED]) {
    const organizationsWithoutCurrent = state.organizations?.filter(({ id }) => id.toString() !== action?.payload?.id?.toString());
    const newOrganization = action.payload;
    return {
      ...state,
      currentOrganization: newOrganization,
      organizations: [...organizationsWithoutCurrent, newOrganization],
    };
  }
  else if (action.type === UPDATE_CURRENT_USER[SUCCEEDED]) {
    return {
      ...state,
      currentUser: action.payload,
    };
  }
  else if (action.type === GET_ORGANIZATION_STATUS[SUCCEEDED]) {
    return {
      ...state,
      currentOrganization: {
        ...state.currentOrganization,
        status: action.payload.status,
      },
    };
  }
  return state;
};

export default globalReducer;