import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTE_BRANDS } from '../../routes';
import { selectCurrentOrganizationId, selectIsCurrentOrganizationAdmin } from '../../store/selectors/global';

const ProtectedRoute = () => {
    const isAdmin = useSelector(selectIsCurrentOrganizationAdmin);
    const currentOrganizationId = useSelector(selectCurrentOrganizationId);

    if (!isAdmin) return <Navigate to={ROUTE_BRANDS(currentOrganizationId)} replace />;
  
    return <Outlet />;
  };

  export default ProtectedRoute