export const STARTED = "STARTED";
export const SUCCEEDED = "SUCCEEDED";
export const FAILED = "FAILED";

const createAsyncActionType = (typeName) => ({
    [STARTED]: `${typeName}_${STARTED}`,
    [SUCCEEDED]: `${typeName}_${SUCCEEDED}`,
    [FAILED]: `${typeName}_${FAILED}`,
});

export const INITIALIZE_APP = createAsyncActionType('INITIALIZE_APP');
export const LOGIN_USER = createAsyncActionType('LOGIN_USER');
export const UPDATE_CURRENT_ORGANIZATION = createAsyncActionType('UPDATE_CURRENT_ORGANIZATION');
export const UPDATE_CURRENT_USER = createAsyncActionType('UPDATE_CURRENT_USER');
export const GET_ORGANIZATION_STATUS = createAsyncActionType('GET_ORGANIZATION_STATUS');