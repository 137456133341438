export const ROUTE_LOGIN = () => '/login';
export const ROUTE_LOGOUT = () => '/logout';
export const ROUTE_SEND_FORGOT_PASSWORD_EMAIL = organizationId => `/forgot-password`;
export const ROUTE_HOME = () => '/';

export const ROUTE_NO_ORGANIZATIONS = () => '/no-organizations';
export const ROUTE_APP_LOADING_ERROR = () => '/error';

export const ROUTE_DASHBOARD = organizationId => `/${organizationId}/dashboard`;

export const ROUTE_BRANDS = organizationId => `/${organizationId}/brands`;
export const ROUTE_ACCOUNTS = (organizationId, brandId) => `/${organizationId}/brands/${brandId}`;
export const ROUTE_ACCOUNTS_AMAZON_ADS_CONNECTOR = (organizationId, brandId, connectorId, country) =>
    `/${organizationId}/brands/${brandId}/amazon-ads/connect/${connectorId}/country/${country}`;

export const ROUTE_REPORTS = organizationId => `/${organizationId}/reports`;

export const ROUTE_TABLES = organizationId => `/${organizationId}/tables`;
export const ROUTE_TABLE_SAMPLES = (organizationId, tableId) => `/${organizationId}/tables/${tableId}`;

export const ROUTE_TEAM = organizationId => `/${organizationId}/team`;
export const ROUTE_TEAM_USERS = organizationId => `/${organizationId}/team/users`;
export const ROUTE_TEAM_INVITES = organizationId => `/${organizationId}/team/invites`;

export const ROUTE_ORGANIZATION_SETTINGS = organizationId => `/${organizationId}/settings`;
export const ROUTE_ORGANIZATION_SETTINGS_GENERAL = organizationId => `/${organizationId}/settings/general`;
export const ROUTE_ORGANIZATION_CHATS = organizationId => `/${organizationId}/chat`;
export const ROUTE_ORGANIZATION_SETTINGS_BILLING = organizationId => `/${organizationId}/settings/billing`;
export const ROUTE_ORGANIZATION_SETTINGS_INVOICES = organizationId => `/${organizationId}/settings/invoices`;
export const ROUTE_ORGANIZATION_SETTINGS_DATA_WAREHOUSE = organizationId =>
    `/${organizationId}/settings/data-warehouse`;

export const ROUTE_USER_SETTINGS = organizationId => `/${organizationId}/profile`;
export const ROUTE_USER_SETTINGS_GENERAL = organizationId => `/${organizationId}/profile/general`;
export const ROUTE_USER_SETTINGS_PASSWORD = organizationId => `/${organizationId}/profile/password`;

// Routes to allow users to manage account even when they have no organization
export const ROUTE_USER_SETTINGS_GENERAL_GLOBAL = organizationId => `/profile/general`;
export const ROUTE_USER_SETTINGS_PASSWORD_GLOBAL = organizationId => `/profile/password`;
