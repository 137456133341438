import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import Loading from './components/loading';
import useInitializeApp from './utils/hooks/useInitializeApp';
import ProtectedRoute from './components/protectedRoute';
import { APP_STATUS } from './constants/global';
import { ROUTE_ORGANIZATION_SETTINGS_GENERAL, ROUTE_USER_SETTINGS_GENERAL } from './routes';
import {
    selectAppStatus,
    selectCurrentOrganizationId,
    selectIsCurrentOrganizationAdmin,
} from './store/selectors/global';
const Chat = React.lazy(() => import('./views/chat'));
const NoOrganizationsPage = React.lazy(() => import('./views/noOrganizations'));
const AppLoadingErrorPage = React.lazy(() => import('./views/appLoadingError'));
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Page404 = React.lazy(() => import('./views/error'));
const Dashboard = React.lazy(() => import('./views/Home'));
const Brands = React.lazy(() => import('./views/brands'));
const Reports = React.lazy(() => import('./views/reports/index'));
const Tables = React.lazy(() => import('./views/tables/index'));
const TableSample = React.lazy(() => import('./views/tables/TableSamplePage'));
const Team = React.lazy(() => import('./views/team/index'));
const Accounts = React.lazy(() => import('./views/accounts'));
const OrganizationSettings = React.lazy(() => import('./views/organizationSettings'));
const UserSettings = React.lazy(() => import('./views/userSettings'));
const AmazonAuthCallback = React.lazy(() => import('./views/amazonAuthCallback'));
const AmazonAdsAccountsPicker = React.lazy(() => import('./views/amazonAdsAccountPicker'));
const LogoutPage = React.lazy(() => import('./views/logout'));
const BillingTab = React.lazy(() => import('./views/organizationSettings/tabs/BillingTab'));
const InvoicesTab = React.lazy(() => import('./views/organizationSettings/tabs/InvoicesTab'));
const OrganizationGeneralTab = React.lazy(() => import('./views/organizationSettings/tabs/GeneralTab'));
const DataWarehouseUsersTab = React.lazy(() => import('./views/organizationSettings/tabs/DataWarehouseUsersTab'));
const UserGeneralTab = React.lazy(() => import('./views/userSettings/tabs/GeneralTab'));
const PasswordTab = React.lazy(() => import('./views/userSettings/tabs/PasswordTab'));
const VerifyEmail = React.lazy(() => import('./views/verifyEmail'));

const propTypes = {
    auth: PropTypes.object.isRequired,
};

const AuthedRouter = ({ auth }) => {
    const organizationId = useSelector(selectCurrentOrganizationId);
    const isAdmin = useSelector(selectIsCurrentOrganizationAdmin);
    const appStatus = useSelector(selectAppStatus);
    useInitializeApp();

    return (
        <Routes>
            <Route path="/logout" name="Logout" exact element={<LogoutPage auth={auth} />} />
            <Route path="/no-organizations" name="No organizations" exact element={<NoOrganizationsPage />} />
            <Route path="/error" name="No organizations" exact element={<AppLoadingErrorPage />} />
            <Route exact path="/verify-email" element={<VerifyEmail auth={auth} />} />
            <Route path="/profile" exact element={<UserSettings isGlobal />}>
                <Route path="general" exact element={<UserGeneralTab />} />
                <Route path="password" exact element={<PasswordTab />} />
                <Route index element={<Navigate to={ROUTE_USER_SETTINGS_GENERAL(organizationId)} />} />
            </Route>
            {appStatus === APP_STATUS.LOADED ? (
                <Route element={<DefaultLayout />}>
                    <Route path="/:organizationId/chat" element={<Chat />} />
                    <Route path="/" exact element={<Navigate to={`/${organizationId}/brands`} />} />
                    <Route path="/auth" exact element={<AmazonAuthCallback />} />
                    <Route path="/:organizationId/dashboard" exact element={<Dashboard />} />
                    <Route path="/:organizationId/reports" exact element={<Reports />} />
                    <Route path="/:organizationId/tables/:tableId" exact element={<TableSample />} />
                    <Route path="/:organizationId/tables" exact element={<Tables />} />
                    <Route path="/:organizationId/brands" exact element={<Brands />} />
                    <Route
                        path="/:organizationId/brands/:brandId/amazon-ads/connect/:connectorId/country/:country"
                        exact
                        element={<AmazonAdsAccountsPicker />}
                    />
                    <Route path="/:organizationId/brands/:brandId" exact element={<Accounts />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/:organizationId/team/*" element={isAdmin ? <Team /> : <Page404 />} />
                        <Route path="/:organizationId/settings" exact element={<OrganizationSettings />}>
                            <Route path="general" exact element={<OrganizationGeneralTab />} />
                            <Route path="billing" exact element={<BillingTab />} />
                            <Route path="invoices" exact element={<InvoicesTab />} />
                            <Route path="data-warehouse" exact element={<DataWarehouseUsersTab />} />
                            <Route
                                index
                                element={<Navigate to={ROUTE_ORGANIZATION_SETTINGS_GENERAL(organizationId)} />}
                            />
                        </Route>
                    </Route>
                    <Route path="/:organizationId/profile" exact element={<UserSettings />}>
                        <Route path="general" exact element={<UserGeneralTab />} />
                        <Route path="password" exact element={<PasswordTab />} />
                        <Route index element={<Navigate to={ROUTE_USER_SETTINGS_GENERAL(organizationId)} />} />
                    </Route>
                    <Route path="*" name="Page 404" element={<Page404 />} />
                </Route>
            ) : (
                <Route path="*" element={<Loading />} />
            )}
        </Routes>
    );
};

AuthedRouter.propTypes = propTypes;

export default AuthedRouter;
