import React from 'react'; 

import { StyledLoadingEllipsis } from './Loading.styles';

const LoadingEllipsis = () => {
    return (
      <StyledLoadingEllipsis>
        <div><div className="loader"></div></div>
      </StyledLoadingEllipsis>
    );
};
  
export default LoadingEllipsis;
  