import React from 'react'; 
import PropTypes from 'prop-types';

import { StyledLoadingWheel, LOADER_SIZES, LOADER_THEMES} from './Loading.styles';

const propTypes = {
  size: PropTypes.oneOf(Object.values(LOADER_SIZES)),
  theme: PropTypes.oneOf(Object.values(LOADER_THEMES)),
};

const defaultProps = {
  sizes: LOADER_SIZES.REGULAR,
  theme: LOADER_THEMES.DARK,
};

const LoadingWheel = ({ size, theme }) => {
    return (
      <StyledLoadingWheel size={size} theme={theme}>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </StyledLoadingWheel>
    );
};

LoadingWheel.propTypes = propTypes;
LoadingWheel.defaultProps = defaultProps;
  
export default LoadingWheel;
  