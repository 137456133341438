import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';

const Login = React.lazy(() => import('./views/login/index'));
const Register = React.lazy(() => import('./views/register'));
const Invitation = React.lazy(() => import('./views/invitation'));
const ResetPassword = React.lazy(() => import('./views/resetPassword'));
const ForgotPassword = React.lazy(() => import('./views/forgotPassword'));
const VerifyEmail = React.lazy(() => import('./views/verifyEmail'));

const propTypes = { 
    auth: PropTypes.object.isRequired
};

const AuthedRouter = ({ auth }) => (
    <Routes>
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/invitation" element={<Invitation />} />
        <Route exact path="/forgot-password" element={<ForgotPassword auth={auth} />} />
        <Route exact path="/verify-email" element={<VerifyEmail auth={auth} />} />
        <Route exact path="/recover" element={<ResetPassword auth={auth} />} />
        <Route path="*" element={<Login auth={auth} />} />
    </Routes>
);

AuthedRouter.propTypes = propTypes;

export default AuthedRouter;
