import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { initializeApp } from 'firebase/app';
import { getAuth } from '@firebase/auth';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import firebaseConfig from './firebase/config';

import './assets/css/App.scss';
import Loading from './components/loading';
import useAuth from './utils/hooks/useAuth';

import AuthedRouter from './AuthedRouter';
import UnauthedRouter from './UnauthedRouter';

import { GlobalStyle } from './App.styles';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);

const App = () => {
    const { loggedIn } = useAuth({ auth });

    return (
        <Suspense fallback={<Loading />}>
            <GlobalStyle />
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                        {
                            (!!loggedIn && <AuthedRouter auth={auth} />)
                            || (loggedIn !== null && <UnauthedRouter auth={auth} />)
                            ||<Loading/>
                        }
                </QueryParamProvider>
            </BrowserRouter>
        </Suspense>
    );
};

export default App;
