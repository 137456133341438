export const COLOURS_LIGHT = {
    BACKGROUND: '#FFFFFF',
    SURFACE: '#F3F3F7',
    PRIMARY: '#4A0BB5',
    SECONDARY: '#2400FE',
    TERTIARY: '#C8247D',
    RELIEF: 'rgba(0, 0, 0, 0.2)',
    OFFSET: 'rgba(0, 0, 0, 0.1)',
    OUTLINE: '#E4EAEF',
    DISABLED: 'rgba(0, 0, 0, 0.04)',
    SUCCESS: '#06A77D',
    SUCCESS_LIGHT: '#D5FCC7',
    WARNING: '#F68B28',
    DANGER: '#DB5461',
    HIGHLIGHT: '#E3E0F4',
    ALTERNATES: ['#FF4655', '#0073D9', '#DC4182', '#A54F99', '#6265DB', '#685696', '#3A537C', '#23B9E8', '#2F4858', '#6F9A00', '#574240', '#396600', '#4C7D93', '#61B5D9'],
    FONTS: {
        DARK: {
            PRIMARY: '#000000',
            SECONDARY: 'rgba(129, 138, 161, 1)',
            HINT: 'rgba(0, 0, 0, 0.5)',
            CONTRAST: '#8997A3'
        },
        LIGHT: {
            PRIMARY: 'rgba(255, 255, 255, 1.0)',
            SECONDARY: 'rgba(255, 255, 255, 0.5)',
            HINT: 'rgba(255, 255, 255, 0.3)',
        },
        SURFACE: {
            PRIMARY: 'rgb(68, 78, 89)',
        }
    },
    INVISIBLE: {
        DARK: 'rgba(0, 0, 0, 0)',
        LIGHT: 'rgba(255, 255, 255, 0)',
    },
};

export const COLOURS_DARK = {
    BACKGROUND: 'rgba(29, 30, 38, 1)',
    SURFACE: '#252731',
    PRIMARY: 'rgb(137, 77, 240)',
    SECONDARY: '#2400FE',
    RELIEF: 'rgba(0, 0, 0, 0.2)',
    OFFSET: 'rgba(0, 0, 0, 0.1)',
    OUTLINE: 'rgba(200, 203, 219, 0.3)',
    DISABLED: 'rgba(0, 0, 0, 0.3)',
    SUCCESS: '#06A77D',
    SUCCESS_LIGHT: '#D5FCC7',
    WARNING: '#F68B28',
    DANGER: '#DB5461',
    HIGHLIGHT: '#252731',
    ALTERNATES: ['#FF4655', '#0073D9', '#DC4182', '#A54F99', '#6265DB', '#685696', '#3A537C', '#23B9E8', '#2F4858', '#6F9A00', '#574240', '#396600', '#4C7D93', '#61B5D9'],
    FONTS: {
        DARK: {
            PRIMARY: 'rgba(255, 255, 255, 1.0)',
            SECONDARY: 'rgba(200, 203, 219, 0.5)',
            HINT: 'rgba(255, 255, 255, 0.3)',
        },
        LIGHT: {
            PRIMARY: '#FFFFFF',
            SECONDARY: 'rgba(0, 0, 0, 0.4)',
            HINT: 'rgba(0, 0, 0, 0.3)',
            CONTRAST: '#8997A3',
        },
        SURFACE: {
            PRIMARY: 'rgba(200, 203, 219, 0.7)',
        }
    },
    INVISIBLE: {
        DARK: 'rgba(255, 255, 255, 0)',
        LIGHT: 'rgba(0, 0, 0, 0)',
    },
};

const theme = localStorage.getItem('theme') ?? 'light';
export const COLOURS = theme === "light" ? COLOURS_LIGHT : COLOURS_DARK;

export const FONTS = {
    FAMILY: '\'Inter\', Arial, Helvetica Neue, Helvetica, sans-serif',
    SIZES: {
        VERY_SMALL: '8px',
        SMALL: '12px',
        REGULAR: '14px',
        LARGE: '18px',
        VERY_LARGE: '24px',
    },
    WEIGHT: {
        REGULAR: '400',
        MEDIUM: '500',
        BOLD: '600',
        BLACK: '700',
    },
};

export const SPACING = {
    VERY_SMALL: '4px',
    SMALL: '12px',
    REGULAR: '24px',
    LARGE: '32px',
    VERY_LARGE: '48px',
};

export const MISC = {
    PAGE: {
        BORDER_RADIUS: '8px',
        BOX_SHADOW: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    BUTTON: {
        BORDER_RADIUS: '6px',
        GRADIENT: 'linear-gradient(to right, #03748C 0%, #4389A2 51%, #03748C 100%)',
    },
    AVATAR: {
        BORDER_RADIUS: '100px',
    },
    SELECT: {
        BOX_SHADOW: '0px 1px 4px rgba(0, 0, 0, 0.2)',
        BORDER_RADIUS: '10px',
    },
    LOGO: {
        GRADIENT: 'linear-gradient(269deg, #FF4655 17.18%, #2400FE 96.87%)',
    },
    CARD: {
        BOX_SHADOW: '0px 2px 4px rgba(0, 0, 0, 0.1)'
    }
};
