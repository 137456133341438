import styled from 'styled-components/macro';

import { COLOURS, SPACING } from '../../constants/styles';

export const LOADER_KINDS = {
    WHEEL: "WHEEL",
    ELLIPSIS: "ELLIPSIS",
};

export const LOADER_MODES = {
    FULLSCREEN: "FULLSCREEN",
    FILL_PARENT: "FILL",
    INLINE: "INLINE",
    INLINE_CENTERED: "INLINE_CENTERED"
};

export const LOADER_SIZES = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
}

export const LOADER_THEMES = {
    DARK: "DARK",
    LIGHT: "LIGHT",
};

export const StyledLoadingContainer = styled.div`
    ${({ mode }) => (mode === LOADER_MODES.FILL_PARENT && `
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    `)
    || (mode === LOADER_MODES.FULLSCREEN && `
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `)
    || (mode === LOADER_MODES.INLINE_CENTERED && `
        width: 100%;
        margin: ${SPACING.SMALL} 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    `)
    || `
        display: flex;
        flex-direction: column;
        position: relative;
    `};
`;

export const StyledLoadingMessage = styled.div`
    color: ${COLOURS.FONTS.DARK.SECONDARY};
    margin-top: ${SPACING.SMALL};
`;

export const StyledLoadingWheel = styled.div`
    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
        }
    }
    @keyframes rotateOpacity {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            opacity: 0.1;
        }
        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
            opacity: 1;
        }
    }
  
    width: ${({ size }) => (size === LOADER_SIZES.SMALL && '24px')
        || (size === LOADER_SIZES.MEDIUM && '36px')
        || '48px'
    };
    height: ${({ size }) => (size === LOADER_SIZES.SMALL && '24px')
        || (size === LOADER_SIZES.MEDIUM && '36px')
        || '48px'
    };
    border-radius: 50%;
    border: 3px solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  
    >.effect-1,
    >.effect-2,
    > .effect-3 {
    width: ${({ size }) => (size === LOADER_SIZES.SMALL && '20px')
        || (size === LOADER_SIZES.MEDIUM && '32px')
        || '44px'
    };
    height: ${({ size }) => (size === LOADER_SIZES.SMALL && '20px')
        || (size === LOADER_SIZES.MEDIUM && '32px')
        || '44px'
    };
    border-radius: 50%;
    border: 3px solid transparent;
    border-left: 3px solid ${({ theme }) => theme === LOADER_THEMES.DARK ? COLOURS.PRIMARY : COLOURS.FONTS.LIGHT.PRIMARY};
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    transform-origin: center center;
    }

    > .effect-1 {
    position: absolute;
    animation: rotate 1s ease infinite;
    }
    >.effect-2 {
    position: absolute;
    animation: rotateOpacity 1s ease infinite 0.1s;
    }
    > .effect-3 {
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
    }

    >.loading .effects {
    transition: all 0.3s ease;
    }
}
`;

export const StyledLoadingEllipsis = styled.div`
    > div {
        width: 100%;
        height: 40px;
    }

    .loader, .loader:before, .loader:after {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
    }

     .loader {
        color: ${COLOURS.OUTLINE};
        font-size: 7px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
    }

    .loader:before, .loader:after {
        content: '';
        position: absolute;
        top: 0;
    }

    .loader:before {
        left: -20px;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 20px;
    }
    
    @keyframes bblFadInOut {
        0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
        40% { box-shadow: 0 2.5em 0 0 }
    }   
`;
