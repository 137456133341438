import requests from './requests';

// Logged out
export const LOGIN_USER = payload => requests.post('/auth/login', payload);
export const REGISTER_ORGANIZATION = payload => requests.post('/auth/register', payload);
export const GET_BILLING_PLAN = billingPlanId => requests.get(`/unauthenticated/billing-plans/${billingPlanId}`);
export const VERIFY_INVITE = inviteCode => requests.post(`/auth/verify-invite`, { inviteCode });
export const ACCEPT_INVITE = invitation => requests.post(`/auth/users`, invitation);
export const SEND_PASSWORD_RESET_EMAIL = email => requests.post(`/auth/recover`, { email });

// Globals
export const INITIALIZE_APP = ({ organizationId } = { organizationId: 0 }) => requests.get(`/api/${organizationId}/initialize`);
export const GET_ORGANIZATIONS = () => requests.get('/api/organizations');
export const GET_BILLING_PLANS = () => requests.get('/api/billing-plans');
export const GET_ORGANIZATION_STATUS = ({ organizationId }) => requests.get(`/api/organizations/${organizationId}/status`);

// Brands
export const GET_BRAND = (organizationId, brandId) => requests.get(`/api/${organizationId}/brands/${brandId}`);
export const GET_BRANDS = organizationId => requests.get(`/api/${organizationId}/brands`);
export const CREATE_BRAND = (organizationId, payload) => requests.post(`/api/${organizationId}/brands`, payload);
export const DELETE_BRAND = (organizationId, brandId) => requests.delete(`/api/${organizationId}/brands/${brandId}`);

// SP Accounts
export const GET_ACCOUNTS = (organizationId, brandId) => requests.get(`/api/${organizationId}/brands/${brandId}/accounts`);
export const DELETE_ACCOUNT = (organizationId, brandId, accountId) => requests.delete(`/api/${organizationId}/brands/${brandId}/accounts/${accountId}`);
export const CREATE_SP_ACCOUNT = (organizationId, brandId, payload) => requests.post(`/api/${organizationId}/brands/${brandId}/accounts`, payload);

// Ads Accounts
export const CREATE_AMAZON_ADS_CONNECTOR = (organizationId, brandId, payload) => requests.post(`/api/${organizationId}/brands/${brandId}/amazon-ads-connectors`, payload);
export const CREATE_AMAZON_ADS_ACCOUNTS = (organizationId, brandId, payload) => requests.post(`/api/${organizationId}/brands/${brandId}/amazon-ads-accounts`, payload);
export const GET_AMAZON_ADS_ACCOUNTS_FOR_CONNECTOR = (organizationId, brandId, connectorId, country) => requests.get(`/api/${organizationId}/brands/${brandId}/amazon-ads-connectors/${connectorId}/country/${country}`);

// Page views
export const GET_DASHBOARD = organizationId => requests.get(`/api/${organizationId}/dashboard`);
export const GET_REPORTS = () => requests.get(`/api/reports`);
export const GET_TABLES = organizationId => requests.get(`/api/${organizationId}/tables`);
export const GET_TABLE_SAMPLE = (organizationId, tableId) => requests.get(`/api/${organizationId}/tables/${tableId}`);

// Users
export const GET_USERS = organizationId => requests.get(`/api/${organizationId}/team/users`);
export const UPDATE_USER_RELATIONSHIP = (organizationId, relationship) => requests.put(`/api/${organizationId}/team/users`, relationship);
export const REMOVE_USER = (organizationId, userId) => requests.delete(`/api/${organizationId}/team/users/${userId}`);

// Invites
export const GET_INVITES = organizationId => requests.get(`/api/${organizationId}/team/invites`);
export const INVITE_USER = (organizationId, invite) => requests.post(`/api/${organizationId}/team/invites`, invite);
export const REVOKE_INVITE = (organizationId, inviteId) => requests.delete(`/api/${organizationId}/team/invites/${inviteId}`);

// Billing
export const GET_BILLING_SUBSCRIPTION = organizationId => requests.get(`/api/${organizationId}/settings/billing`);
export const GET_BILLING_INVOICES = organizationId => requests.get(`/api/${organizationId}/settings/invoices`);
export const GET_BILLING_PAYMENT_METHOD = organizationId => requests.get(`/api/${organizationId}/settings/payment`);
export const UPDATE_BILLING_PAYMENT_METHOD = (organizationId, payload) => requests.post(`/api/${organizationId}/settings/payment`, payload);
export const UPDATE_CURRENT_ORGANIZATION = ({ organizationId, payload }) => requests.post(`/api/${organizationId}/settings`, payload);
export const PREVIEW_SUBSCRIPTION_UPDATE = ({ organizationId, newPlanId }) => requests.get(`/api/${organizationId}/settings/billing/${newPlanId}/preview`);
export const UPDATE_BILLING_SUBSCRIPTION = ({ organizationId, newPlanId }) => requests.get(`/api/${organizationId}/settings/billing/${newPlanId}`);

export const GET_DATA_WAREHOUSE_USERS = organizationId => requests.get(`/api/${organizationId}/data-warehouse/access`);
export const ADD_DATA_WAREHOUSE_USER =(organizationId, email) => requests.post(`/api/${organizationId}/data-warehouse/access/add`, { email });
export const REMOVE_DATA_WAREHOUSE_USER = (organizationId, email) => requests.post(`/api/${organizationId}/data-warehouse/access/remove`, { email });

// Profile
export const UPDATE_CURRENT_USER = newUser => requests.post(`/api/profile`, newUser);
export const SEND_EMAIL_VERIFICATION = () => requests.get(`/api/profile/verify-email`);